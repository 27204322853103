import { 
  collection, 
  doc, 
  getDoc, 
  getDocs, 
  setDoc, 
  addDoc, 
  updateDoc, 
  deleteDoc, 
  query, 
  where, 
  limit, 
  orderBy, 
  startAfter, 
  serverTimestamp 
} from 'firebase/firestore';
import { db } from '../config/firebase';

/**
 * Generic service for Firestore operations
 */
export const firestoreService = {
  // Get a document by ID
  getDocument: async (collectionName, id) => {
    try {
      const docRef = doc(db, collectionName, id);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        return { id: docSnap.id, ...docSnap.data() };
      }
      return null;
    } catch (error) {
      console.error(`Error getting document from ${collectionName}:`, error);
      throw error;
    }
  },
  
  // Get all documents from a collection
  getCollection: async (collectionName) => {
    try {
      const querySnapshot = await getDocs(collection(db, collectionName));
      return querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    } catch (error) {
      console.error(`Error getting collection ${collectionName}:`, error);
      throw error;
    }
  },
  
  // Query documents with pagination
  queryDocuments: async (collectionName, conditions = [], sortField = 'createdAt', sortDirection = 'desc', pageSize = 10, lastVisible = null) => {
    try {
      let queryRef = collection(db, collectionName);
      
      // Build query with conditions
      if (conditions.length > 0) {
        const filters = conditions.map(condition => where(condition.field, condition.operator, condition.value));
        queryRef = query(queryRef, ...filters);
      }
      
      // Add sorting
      queryRef = query(queryRef, orderBy(sortField, sortDirection));
      
      // Add pagination
      if (pageSize) {
        queryRef = query(queryRef, limit(pageSize));
      }
      
      // Add starting point for pagination
      if (lastVisible) {
        queryRef = query(queryRef, startAfter(lastVisible));
      }
      
      const querySnapshot = await getDocs(queryRef);
      
      // Get the last document for pagination
      const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
      
      return {
        data: querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })),
        lastVisible: lastDoc
      };
    } catch (error) {
      console.error(`Error querying ${collectionName}:`, error);
      throw error;
    }
  },
  
  // Create a document with automatic ID
  addDocument: async (collectionName, data) => {
    try {
      const docRef = await addDoc(collection(db, collectionName), {
        ...data,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });
      
      return docRef.id;
    } catch (error) {
      console.error(`Error adding document to ${collectionName}:`, error);
      throw error;
    }
  },
  
  // Create or update a document with specific ID
  setDocument: async (collectionName, id, data, merge = true) => {
    try {
      const docRef = doc(db, collectionName, id);
      await setDoc(docRef, {
        ...data,
        updatedAt: serverTimestamp()
      }, { merge });
      
      return id;
    } catch (error) {
      console.error(`Error setting document in ${collectionName}:`, error);
      throw error;
    }
  },
  
  // Update a document
  updateDocument: async (collectionName, id, data) => {
    try {
      const docRef = doc(db, collectionName, id);
      await updateDoc(docRef, {
        ...data,
        updatedAt: serverTimestamp()
      });
      
      return id;
    } catch (error) {
      console.error(`Error updating document in ${collectionName}:`, error);
      throw error;
    }
  },
  
  // Delete a document
  deleteDocument: async (collectionName, id) => {
    try {
      const docRef = doc(db, collectionName, id);
      await deleteDoc(docRef);
      
      return id;
    } catch (error) {
      console.error(`Error deleting document from ${collectionName}:`, error);
      throw error;
    }
  }
}; 