import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "../config/firebase"; // Assuming messaging is exported from your config
import { firestoreService } from "../services/firestore";

// Function to request notification permission
export const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      
      return true;
    } else {
      
      return false;
    }
  } catch (error) {
    console.error("Error requesting notification permission:", error);
    return false;
  }
};

// Function to get the FCM token
export const getFCMToken = async (vapidKey) => {
  if (!messaging) {
    console.error("Firebase Messaging is not initialized.");
    return null;
  }
  if (!vapidKey) {
    console.error("VAPID key is required to get FCM token.");
    return null;
  }

  try {
    const currentToken = await getToken(messaging, { vapidKey: vapidKey });
    if (currentToken) {
      
      return currentToken;
    } else {
      
      // Optional: Request permission if not granted
      // const granted = await requestNotificationPermission();
      // if (granted) return getFCMToken(vapidKey); // Retry getting token
      return null;
    }
  } catch (error) {
    console.error("An error occurred while retrieving token. ", error);
    return null;
  }
};

// Function to save the FCM token to Firestore for a user
export const saveTokenToFirestore = async (userId, token) => {
  if (!userId || !token) {
    console.error("User ID and token are required to save token.");
    return;
  }
  try {
    await firestoreService.setDocument("user_tokens", userId, {
      device: "web",
      token: token,
      createdAt: new Date(),
    });
    
  } catch (error) {
    console.error("Error saving FCM token to Firestore:", error);
  }
};

// Function to setup foreground message handling
export const onForegroundMessage = (callback) => {
  if (!messaging) {
    console.error("Firebase Messaging is not initialized.");
    return null;
  }
  return onMessage(messaging, (payload) => {
    
    // Trigger the callback with the payload
    // The app can then decide how to display this (e.g., a toast notification)
    if (callback) {
      callback(payload);
    }
  });
};
