import React, { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
  updateProfile,
  sendEmailVerification,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "../config/firebase";
import { firestoreService } from "../services/firestore";
import {
  requestNotificationPermission,
  getFCMToken,
  saveTokenToFirestore,
  onForegroundMessage,
} from "../services/notificationService";

// Create the authentication context
const AuthContext = createContext();

// Custom hook to use the auth context
export const useAuth = () => {
  return useContext(AuthContext);
};

// Auth Provider component
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [verificationRequired, setVerificationRequired] = useState(false);
  const [unverifiedEmail, setUnverifiedEmail] = useState(null);
  const [notificationPermissionGranted, setNotificationPermissionGranted] =
    useState(false);
  const [isPremiumUser, setIsPremiumUser] = useState(false);
  const [tokenRefreshed, setTokenRefreshed] = useState(false);

  // Register a new user
  const signup = async (email, password, displayName) => {
    try {
      setError("");
      const result = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      await updateProfile(result.user, { displayName });
      await sendEmailVerification(result.user);
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  // Login with email and password
  const login = async (email, password) => {
    try {
      setError("");
      const result = await signInWithEmailAndPassword(auth, email, password);

      // No need to throw error for unverified email
      // The auth state change listener will set verificationRequired
      // and the redirector will handle redirecting to the verification page

      return result.user;
    } catch (err) {
      if (
        err.code === "auth/user-not-found" ||
        err.code === "auth/wrong-password"
      ) {
        setError("Email ou senha incorretos. Por favor, tente novamente.");
      } else {
        setError(err.message);
      }
      throw err;
    }
  };

  // Login with Google
  const loginWithGoogle = async () => {
    try {
      setError("");
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      return result.user;
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  // Login with Apple
  const loginWithApple = async () => {
    try {
      setError("");
      const provider = new OAuthProvider("apple.com");
      provider.addScope("email");
      provider.addScope("name");

      const result = await signInWithPopup(auth, provider);
      return result.user;
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  // Resend verification email
  const resendVerificationEmail = async () => {
    try {
      setError("");
      if (!auth.currentUser) {
        // Try to sign in temporarily to send verification email
        if (unverifiedEmail) {
          await signInWithEmailAndPassword(auth, unverifiedEmail, "");
        } else {
          throw new Error("No user is currently logged in");
        }
      }

      const actionCodeSettings = {
        url: "https://studyfy.com.br/auth/action",
        handleCodeInApp: false,
      };
      await sendEmailVerification(auth.currentUser, actionCodeSettings);
      // Sign out after sending verification
      if (unverifiedEmail) {
        await signOut(auth);
      }

      return true;
    } catch (err) {
      if (err.code === "auth/wrong-password") {
        // This is expected since we're trying with empty password
        // We want to access the user object to send verification
        if (auth.currentUser) {
          const actionCodeSettings = {
            url: "https://studyfy.com.br/auth/action",
            handleCodeInApp: false,
          };
          await sendEmailVerification(auth.currentUser, actionCodeSettings);
          await signOut(auth);
          return true;
        }
      }
      setError(
        "Falha ao enviar email de verificação. Por favor, tente novamente mais tarde."
      );
      throw err;
    }
  };

  // Logout user
  const logout = () => {
    setError("");
    setVerificationRequired(false);
    setUnverifiedEmail(null);
    setTokenRefreshed(false);
    return signOut(auth);
  };

  // Reset password
  const forgotPassword = (email) => {
    setError("");
    const actionCodeSettings = {
      url: "https://studyfy.com.br/auth/action",
      handleCodeInApp: false,
    };
    return sendPasswordResetEmail(auth, email, actionCodeSettings);
  };

  const updateUserProfile = async (data) => {
    try {
      setError("");
      // Update Firebase Auth profile if displayName is provided
      if (data.displayName) {
        await updateProfile(auth.currentUser, {
          displayName: data.displayName,
        });
      }
      setCurrentUser({ ...currentUser, ...data });
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  // Force refresh the user token
  const refreshUserToken = async (user) => {
    if (!user) return null;

    try {
      const token = await user.getIdTokenResult(true);
      setTokenRefreshed(true);
      
      const isPremiumUser = token.claims["stripeRole"] === "premium";
      
      setIsPremiumUser(isPremiumUser);
      return token;
    } catch (err) {
      console.error("Error refreshing token:", err);
      setError("Failed to refresh authentication token");
      return null;
    }
  };

  // Check if user is complete to display the appropriate screens
  const checkUserProfile = async (user) => {
    if (!user) {
      return false;
    }

    try {
      const profile = await firestoreService.getDocument(
        "user_profile",
        user.uid
      );
      return !!profile;
    } catch (err) {
      console.error("Error checking user profile:", err);
      return false;
    }
  };

  // Function to setup notifications for the current user
  const setupNotifications = async (user) => {
    if (!user || !user.uid) return false;

    
    try {
      const permissionGranted = await requestNotificationPermission();
      setNotificationPermissionGranted(permissionGranted);

      if (permissionGranted) {
        const vapidKey = process.env.REACT_APP_FIREBASE_VAPID_KEY;
        if (!vapidKey) {
          console.error(
            "Firebase VAPID key not found in environment variables (REACT_APP_FIREBASE_VAPID_KEY). Cannot get FCM token."
          );
          return false;
        }
        
        const token = await getFCMToken(vapidKey);
        if (token) {
          
          await saveTokenToFirestore(user.uid, token);
          return true;
        } else {
          
          return false;
        }
      } else {
        
        return false;
      }
    } catch (err) {
      console.error("Error setting up notifications:", err);
      return false;
    }
  };

  // Auth state change listener
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(true); // Set loading true at the start of auth check

      if (user) {
        // Set the current user
        setCurrentUser(user);

        // If the user's email is not verified, set the verification required flag
        if (!user.emailVerified) {
          setVerificationRequired(true);
          setUnverifiedEmail(user.email);
        } else {
          setVerificationRequired(false);
          setUnverifiedEmail(null);
        }

        // Reset tokens and states for the new session
        setTokenRefreshed(false);
      } else {
        setCurrentUser(null);
        setVerificationRequired(false);
        setUnverifiedEmail(null);
      }

      setLoading(false);
    });

    return unsubscribe;
  }, []); // Keep dependency array empty to run only on mount/unmount

  // Listener for foreground messages (runs once on context mount)
  useEffect(() => {
    const unsubscribe = onForegroundMessage((payload) => {
      alert(
        `New Notification: ${payload.notification.title}\\n${payload.notification.body}`
      ); // Simple alert for now
    });

    // Cleanup listener on unmount
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []); // Empty dependency array ensures this runs only once

  // Auth context value
  const value = {
    currentUser,
    signup,
    login,
    loginWithGoogle,
    loginWithApple,
    logout,
    forgotPassword,
    updateUserProfile,
    checkUserProfile,
    error,
    verificationRequired,
    unverifiedEmail,
    resendVerificationEmail,
    notificationPermissionGranted,
    setupNotifications,
    refreshUserToken,
    tokenRefreshed,
    isPremiumUser,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
