import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import LoadingScreen from "../common/LoadingScreen";

const AuthRedirector = () => {
  const {
    currentUser,
    loading,
    refreshUserToken,
    checkUserProfile,
    setupNotifications,
  } = useAuth();

  const [isProcessing, setIsProcessing] = useState(false);
  const [processingMessage, setProcessingMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  // Use refs to track completed operations to prevent re-runs
  const operationsRef = useRef({
    tokenRefreshed: false,
    notificationsSetup: false,
    profileChecked: false,
    hasProfile: false
  });

  // Add 
  

  useEffect(() => {
    // Use a single function wrapped with async/await
    const handleAuthRedirection = async () => {
      // Skip if still loading or already processing
      if (loading || isProcessing) return;

      

      // Define path constants
      const emailVerificationPath = "/email-verification";
      const profileCompletionPath = "/complete-profile";
      const dashboardPath = "/dashboard";
      const loginPath = "/auth/login";

      // Identify if the current route is public
      const isPublicRoute =
        location.pathname.startsWith("/auth") ||
        location.pathname.startsWith("/legal") ||
        location.pathname === "/" ||
        location.pathname === emailVerificationPath;

      // RULE 1+2: Check if user is logged in, handle public/private routes
      if (!currentUser) {
        
        // RULE 2.3: If not logged in and trying to access private route, redirect to login
        if (!isPublicRoute && location.pathname !== loginPath) {
          navigate(loginPath, { replace: true });
        }
        // RULE 2.2: If on public route and not logged in, no action needed
        return;
      }

      // From this point on, we know the user is logged in

      setIsProcessing(true);

      try {
        // RULE 3.1: Force token refresh
        if (!operationsRef.current.tokenRefreshed) {
          
          setProcessingMessage("Atualizando sessão...");
          await refreshUserToken(currentUser);
          operationsRef.current.tokenRefreshed = true;
        }

        // RULE 3.2: Check email verification
        if (!currentUser.emailVerified) {
          
          setIsProcessing(false);
          if (location.pathname !== emailVerificationPath) {
            navigate(emailVerificationPath, { replace: true });
          }
          return;
        }

        // If we're on the email verification page but email is verified, redirect to dashboard
        if (location.pathname === emailVerificationPath) {
          
          navigate(dashboardPath, { replace: true });
          setIsProcessing(false);
          return;
        }

        // RULE 3.3: Check if user profile is complete
        if (!operationsRef.current.profileChecked) {
          
          setProcessingMessage("Verificando perfil...");
          const hasProfile = await checkUserProfile(currentUser);
          operationsRef.current.profileChecked = true;
          operationsRef.current.hasProfile = hasProfile;

          // If profile is not complete and not on profile completion page, redirect
          if (!hasProfile && location.pathname !== profileCompletionPath) {
            
            setIsProcessing(false);
            navigate(profileCompletionPath, { replace: true });
            return;
          }

          // If profile is already complete but on profile completion page, redirect to dashboard
          if (hasProfile && location.pathname === profileCompletionPath) {
            
            setIsProcessing(false);
            navigate(dashboardPath, { replace: true });
            return;
          }
        } else if (operationsRef.current.hasProfile && location.pathname === profileCompletionPath) {
          // Use cached profile status to avoid extra checks
          
          setIsProcessing(false);
          navigate(dashboardPath, { replace: true });
          return;
        }

        // RULE 3.4: Setup notifications only once
        if (!operationsRef.current.notificationsSetup && operationsRef.current.hasProfile) {
          
          setProcessingMessage("Configurando notificações...");
          await setupNotifications(currentUser);
          operationsRef.current.notificationsSetup = true;
        }

        // RULE 3.5: All checks passed, redirect to dashboard if on auth routes
        if (location.pathname.startsWith("/auth")) {
          
          navigate(dashboardPath, { replace: true });
        }
      } catch (error) {
        console.error("Error in auth redirection:", error);
      } finally {
        setIsProcessing(false);
      }
    };

    handleAuthRedirection();

    // Include checkUserProfile in deps to run checks when function changes
  }, [
    currentUser,
    loading,
    navigate,
    location.pathname,
    refreshUserToken,
    checkUserProfile,
    setupNotifications
  ]);

  if (loading || isProcessing) {
    return (
      <LoadingScreen
        message={isProcessing ? processingMessage : "Carregando sua sessão..."}
      />
    );
  }

  return null;
};

export default AuthRedirector;
