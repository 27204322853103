import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import {
  School,
  Book,
  Calculate,
  Science,
  AutoStories,
  Psychology,
  Lightbulb,
  HistoryEdu,
} from "@mui/icons-material";

// Default theme for loading screen (before Redux is initialized)
const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#513EEB",
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h5: {
      fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 600,
    },
  },
});

const LoadingScreen = ({ message = "Carregando..." }) => {
  const studyIcons = [
    School,
    Book,
    Calculate,
    Science,
    HistoryEdu,
    Psychology,
    Lightbulb,
    AutoStories,
  ];
  const [currentIconIndex, setCurrentIconIndex] = useState(0);
  const [isAnimating] = useState(true);
  const animationRef = useRef(null);

  // Shuffle icons for randomness like in the Flutter code
  const shuffledIconsRef = useRef(
    [...studyIcons].sort(() => Math.random() - 0.5)
  );

  useEffect(() => {
    let animationTimerId;
    let forward = true;
    let scale = 0.8;
    let rotation = 0;
    const animate = () => {
      if (scale >= 1.2) forward = false;
      if (scale <= 0.8) {
        forward = true;
        // Change icon when animation cycle completes
        setCurrentIconIndex((prev) => (prev + 1) % studyIcons.length);
      }

      scale = forward ? scale + 0.02 : scale - 0.02;
      rotation = forward ? rotation + 0.005 : rotation - 0.005;

      if (animationRef.current) {
        animationRef.current.style.transform = `scale(${scale}) rotate(${rotation}rad)`;
      }

      animationTimerId = requestAnimationFrame(animate);
    };

    if (isAnimating) {
      animationTimerId = requestAnimationFrame(animate);
    }

    return () => {
      cancelAnimationFrame(animationTimerId);
    };
  }, [isAnimating, studyIcons.length]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          gap: 2,
          bgcolor: "background.default",
        }}
      >
        <Box
          component="img"
          src="/illustrations/logo.png"
          alt="Studyfy Logo"
          sx={{
            width: 180,
            height: 180,
            mb: 2,
          }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.style.display = "none";
            document.getElementById("fallback-icon").style.display = "block";
          }}
        />
        <Box
          id="fallback-icon"
          sx={{
            display: "none",
            color: "primary.main",
            fontSize: 100,
            mb: 2,
          }}
        >
          <School fontSize="inherit" />
        </Box>

        {/* App Name */}
        <Typography
          variant="h4"
          component="h1"
          sx={{
            fontFamily: "Poppins",
            fontWeight: 700,
            color: "primary.main",
            mb: 1,
          }}
        >
          Studyfy
        </Typography>

        {/* Subtitle */}
        <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 4 }}>
          Seu assistente de estudos
        </Typography>

        {/* Animated Icons */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 80,
          }}
        >
          {[-1, 0, 1].map((offset) => {
            const iconIndex =
              (currentIconIndex + offset + studyIcons.length) %
              studyIcons.length;
            const IconComponent = shuffledIconsRef.current[iconIndex];
            const isMainIcon = offset === 0;

            return (
              <Box
                key={offset}
                ref={isMainIcon ? animationRef : null}
                sx={{
                  mx: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  transform: isMainIcon ? "scale(1)" : "scale(0.8)",
                  transition: "transform 0.2s",
                }}
              >
                <IconComponent
                  sx={{
                    fontSize: isMainIcon ? 48 : 32,
                    color: isMainIcon
                      ? "primary.main"
                      : (theme) => theme.palette.primary.main + "99",
                  }}
                />
              </Box>
            );
          })}
        </Box>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 4 }}>
          {message}
        </Typography>
      </Box>
    </ThemeProvider>
  );
};

export default LoadingScreen;
