import React, { lazy } from "react";
import { Route, Navigate } from "react-router-dom";
import Layout from "../components/layout/Layout";
import LandingPage from "../pages/landing/LandingPage";

const LoginPage = lazy(() => import("../pages/auth/Login"));
const SignupPage = lazy(() => import("../pages/auth/Signup"));
const ForgotPasswordPage = lazy(() => import("../pages/auth/ForgotPassword"));
const ActionsPage = lazy(() => import("../pages/auth/Actions"));
const EmailNotVerifiedPage = lazy(() =>
  import("../pages/auth/EmailNotVerified")
);
const CompleteProfilePage = lazy(() => import("../pages/auth/CompleteProfile"));
const DashboardPage = lazy(() => import("../pages/dashboard/Dashboard"));
const TermsOfServicePage = lazy(() => import("../pages/legal/TermsOfService"));
const PrivacyPolicyPage = lazy(() => import("../pages/legal/PrivacyPolicy"));

const routes = [
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/auth/login",
    element: <LoginPage />,
  },
  {
    path: "/auth/signup",
    element: <SignupPage />,
  },
  {
    path: "/auth/forgot-password",
    element: <ForgotPasswordPage />,
  },
  {
    path: "/auth/action",
    element: <ActionsPage />,
  },
  {
    path: "/auth",
    element: <Navigate to="/auth/login" replace />,
  },
  {
    path: "/email-verification",
    element: <EmailNotVerifiedPage />,
  },
  {
    path: "/complete-profile",
    element: <CompleteProfilePage />,
  },
  {
    path: "/dashboard",
    element: (
      <Layout>
        <DashboardPage />
      </Layout>
    ),
  },
  {
    path: "/legal/terms-of-service",
    element: <TermsOfServicePage />,
  },
  {
    element: <PrivacyPolicyPage />,
    path: "/legal/privacy-policy",
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
];

export const renderRoutes = () =>
  routes.map((route) => (
    <Route key={route.path} path={route.path} element={route.element} />
  ));

export default routes;
