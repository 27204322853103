import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Routes } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { Provider } from 'react-redux';

// Import font files
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';

// Import store and Providers
import store from './store/store';
import { ThemeProvider } from './contexts/ThemeContext';
import { AuthProvider } from './contexts/AuthContext';
import { NotificationProvider } from './contexts/NotificationContext';

// Import components and routes
import LoadingScreen from './components/common/LoadingScreen';
import AuthRedirector from './components/auth/AuthRedirector';
import { renderRoutes } from './config/routes';

// Error boundary component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Application error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ 
          padding: 20, 
          textAlign: 'center', 
          fontFamily: 'Inter, sans-serif',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
        }}>
          <h1 style={{ color: '#513EEB' }}>Oops, algo deu errado</h1>
          <p>Ocorreu um erro ao carregar a aplicação. Por favor, recarregue a página.</p>
          <button 
            onClick={() => window.location.reload()} 
            style={{
              backgroundColor: '#513EEB',
              color: 'white',
              border: 'none',
              borderRadius: 8,
              padding: '10px 20px',
              marginTop: 20,
              cursor: 'pointer',
              fontFamily: 'inherit',
            }}
          >
            Recarregar aplicação
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

// App with Redux and Theme providers
const App = () => {
  const [loading, setLoading] = useState(true);
  
  // Simulate loading resources
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);
    
    return () => clearTimeout(timer);
  }, []);
  
  if (loading) {
    return <LoadingScreen message="Carregando o Studyfy..." />;
  }
  
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <ThemeProvider>
          <AuthProvider>
            <NotificationProvider>
              <CssBaseline />
              <Router>
                {/* AuthRedirector handles routing based on auth state */}
                <AuthRedirector />
                <Suspense fallback={<LoadingScreen message="Carregando conteúdo..." />}>
                  <Routes>
                    {renderRoutes()}
                  </Routes>
                </Suspense>
              </Router>
            </NotificationProvider>
          </AuthProvider>
        </ThemeProvider>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
