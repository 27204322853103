import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { Snackbar, Alert } from '@mui/material';

// Context for holding notification functionality
const NotificationContext = createContext();

// Hook for accessing the notification context
export const useNotification = () => {
  return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
  // Queue for notifications
  const [queue, setQueue] = useState([]);
  // Current notification being displayed
  const [currentNotification, setCurrentNotification] = useState(null);
  // Whether the snackbar is open
  const [open, setOpen] = useState(false);

  // Add a notification to the queue
  const enqueueNotification = useCallback((message, options = {}) => {
    const notification = {
      key: new Date().getTime() + Math.random(),
      message,
      severity: options.severity || 'info',
      autoHideDuration: options.autoHideDuration || 4000,
      ...options,
    };

    setQueue(currentQueue => [...currentQueue, notification]);
  }, []);

  // Convenience methods for different severity levels
  const showSuccess = useCallback((message, options = {}) => 
    enqueueNotification(message, { severity: 'success', ...options }), 
  [enqueueNotification]);
  
  const showError = useCallback((message, options = {}) => 
    enqueueNotification(message, { severity: 'error', ...options }), 
  [enqueueNotification]);
  
  const showInfo = useCallback((message, options = {}) => 
    enqueueNotification(message, { severity: 'info', ...options }), 
  [enqueueNotification]);
  
  const showWarning = useCallback((message, options = {}) => 
    enqueueNotification(message, { severity: 'warning', ...options }), 
  [enqueueNotification]);

  // Process the queue when it changes or when current notification is displayed/hidden
  useEffect(() => {
    // If snackbar is not open and we have notifications in queue
    if (!open && queue.length > 0) {
      // Take the first notification from queue
      const notification = queue[0];
      setCurrentNotification(notification);
      
      // Remove it from the queue
      setQueue(currentQueue => currentQueue.slice(1));
      
      // Show the notification
      setOpen(true);
    }
  }, [open, queue]);

  // Handle closing of snackbar
  const handleClose = useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }, []);

  // Clear all notifications
  const clearAll = useCallback(() => {
    setQueue([]);
    setOpen(false);
  }, []);

  // Expose the notification API
  const notificationAPI = {
    enqueueNotification,
    showSuccess,
    showError,
    showInfo,
    showWarning,
    clearAll,
  };

  return (
    <NotificationContext.Provider value={notificationAPI}>
      {children}
      
      {currentNotification && (
        <Snackbar
          key={currentNotification.key}
          open={open}
          autoHideDuration={currentNotification.autoHideDuration}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          TransitionProps={{
            onExited: () => {
              setCurrentNotification(null);
            },
          }}
        >
          <Alert 
            onClose={handleClose} 
            severity={currentNotification.severity} 
            variant="filled"
            elevation={6}
            sx={{ width: '100%' }}
          >
            {currentNotification.message}
          </Alert>
        </Snackbar>
      )}
    </NotificationContext.Provider>
  );
}; 